"use client";

import Link from "next/link";
import * as Sentry from "@sentry/nextjs";
import React, { useEffect } from "react";
import styles from "./not-found.module.scss";
import Button from "@components/Common/Button";

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  const errorMessage = error.message || "an unexpected error occurred";

  return (
    <main className={`onepole content`}>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.content}>
            <div className={styles.text}>
              <p className="text-40-100">an unexpected error occurred</p>
              <span className="text-16-100">{errorMessage}</span>
            </div>
            <div className={styles.buttons}>
              <Button>
                <Link href={"/"}>Go back</Link>
              </Button>
              <Button className={"text-12-100"} design="Transparent" onClick={() => reset()}>
                Try Again
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
